import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { style } from './breadcrumb.styles';

@customElement('sbk-breadcrumb')
export class SbkBreadcrumb extends LitElement {

  static get styles() {
      return style;
  }

  firstUpdated() {
    requestAnimationFrame(() => {
      this.updateScrollPosition();
      this.handleScroll();
    });
  }

  updateScrollPosition = () => {
    const container = this.shadowRoot?.querySelector('.container') as HTMLElement;
    const slot = this.shadowRoot?.querySelector('slot');
    const ol = this.shadowRoot?.querySelector('ol') as HTMLElement;
    if (slot && ol) {
      const slottedElements = slot.assignedElements();
      if (container && slottedElements.length > 0) {
        const lastItem = slottedElements[slottedElements.length - 1] as HTMLElement;

        if (container.scrollWidth > container.clientWidth && lastItem) {
          const computedStyle = window.getComputedStyle(this) as CSSStyleDeclaration;
          const paddingLeft = parseFloat(computedStyle.paddingLeft);

          const olComputedStyle = window.getComputedStyle(ol) as CSSStyleDeclaration;
          const gap = parseFloat(olComputedStyle.gap);

          container.scrollLeft = Math.max(0, lastItem.offsetLeft - paddingLeft - gap);
          container.focus();
        }
      }
    }
  }

  render() {
    return html`
      <nav class="container" aria-label="Breadcrumb" @scroll=${this.handleScroll}>
        <div class="fade fade-left"></div>
        <ol>
          <slot></slot>
        </ol>
        <div class="fade fade-right"></div>
      </nav>
    `;
  }

  handleScroll() {
    const container = this.shadowRoot?.querySelector('.container') as HTMLElement;
    const fadeLeft = this.shadowRoot?.querySelector('.fade-left') as HTMLElement;
    const fadeRight = this.shadowRoot?.querySelector('.fade-right') as HTMLElement;

    if (container && fadeLeft && fadeRight) {
      if (container.scrollLeft > 0) {
        fadeLeft.style.display = 'block';
      } else {
        fadeLeft.style.display = 'none';
      }

      if (container.scrollWidth > container.clientWidth && container.scrollLeft < container.scrollWidth - container.clientWidth) {
        fadeRight.style.display = 'block';
      } else {
        fadeRight.style.display = 'none';
      }
    }


  }
}
